<template>
  <nav class="pages_nav nav_sticky">
    <layout-nav></layout-nav>
  </nav>
  <div class="main container">
    <StatementMenu></StatementMenu>
    <div class="content">

      <div class="rules">
        <nav class="rules_nav">
          <h1>协议与规则</h1>
          <div class="right_nav">
            <span>YOUMAI ADVANTAGE</span>
            <div class="right_line"></div>
          </div>
        </nav>
        <div class="rules_main" v-html="rulesText"></div>
                <article>
                  <div class="rules_main">
                    <p></p>
                    欢迎使用我们的PC端公司官网！在使用前，请仔细阅读以下用户协议和规则。您的使用行为将视为对以下条款的同意。
                    <p></p>
                    <h4>一、用户协议</h4>
                    <p>1.1 使用本网站服务的用户，应遵守中国法律法规，尊重网站的知识产权和所有权，不得利用本网站进行任何非法活动。</p>
                    <p>1.2 用户在使用本网站服务时，应遵守以下规定：</p>
                    <ul>
                      <li>不得发布涉及政治、色情、暴力等内容的信息；</li>
                      <li>不得发布虚假、误导性信息；</li>
                      <li>不得侵犯他人的合法权益；</li>
                      <li>不得从事任何损害本网站系统及其数据的行为；</li>
                      <li>不得利用本网站进行任何商业活动。</li>
                    </ul>
                    <p>1.3 用户使用本网站服务时，应保证所提供的信息真实、准确、完整，并保证不会利用本网站进行任何欺诈行为。</p>
                    <p>1.4 本网站有权在发现用户违反以上规定时，立即终止对其提供服务，甚至追究其法律责任。</p>
                    <p>1.5 本网站保留随时修改、变更本协议的权利，并将在本网站上公示修改内容，用户应及时了解协议的最新变更。</p>
                    <p></p>
                    <h4>二、规则</h4>
                    <p>2.1 用户在本网站上发布信息时，应遵守以下规则：</p>
                    <ul>
                      <li>不得发布任何违反法律法规的信息；</li>
                      <li>不得发布任何诽谤、侮辱、谩骂等攻击性言论；</li>
                      <li>不得发布任何广告、营销信息等商业性信息；</li>
                      <li>不得发布与本网站主题无关的信息；</li>
                      <li>不得发布任何色情、暴力、政治等敏感信息。</li>
                    </ul>
                    <p>2.2 用户在本网站上进行交流时，应遵守以下规则：</p>
                    <ul>
                      <li>不得发布任何不当言论，包括但不限于人身攻击、侮辱等；</li>
                      <li>不得发布任何违反法律法规的信息；</li>
                      <li>不得发布与本网站主题无关的信息。</li>
                    </ul>
                    <p>2.3 如用户违反上述规则，本网站有权对其进行警告、删除其信息、禁言或封号等处理。</p>
                    <p>2.4 本网站保留随时修改、变更以上规则的权利，并将在本网站上公示修改内容，用户应及时了解规则的最新变更。</p>
                    <p>以上是我们PC端公司官网的用户协议和规则，请您认真阅读并遵守。如有任何问题或意见，请联系我们的客服。</p>
                  </div>
                </article>
      </div>
    </div>
  </div>

  <footer>
    <FooterView></FooterView>
  </footer>
</template>

<script setup>
// import {useRouter} from "vue-router";
// const router = useRouter()
// const back = () => {
//   router.go(-1)
// }
import { onMounted, ref } from "vue";
import LayoutNav from "@/components/layoutNav.vue";
import FooterView from "@/components/FooterView.vue";
import StatementMenu from "@/components/StatementMenu.vue";
import { getPrivacyProtocal } from "@/request/api"

let rulesText = ref("")
const getRules = () => {
  getPrivacyProtocal("293df747-044a-450d-afe4-e256684740ba").then(res => {
    if (res.data.code === 200) {
      rulesText.value = res.data.data.content
    }
  })
}

onMounted(() => {
  window.scrollTo(0, 0)
  getRules()
})
</script>

<style lang="less" scoped>
.pages_nav {
  width: 100%;
  background-color: #fff;
}

.main {
  margin-top: .3rem;
  display: flex;
  border-radius: .08rem;
  background-color: #fff;

  .content {
    width: 100%;

    .rules {

      //width: 53.333vw;
      .rules_nav {
        padding: .1rem .5rem 0.1rem .1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .right_nav {
          display: flex;
          align-items: center;
          color: #3496FB;
          font-weight: 600;


          .right_line {
            margin-left: .1rem;
            height: .05rem;
            width: .3rem;
            background-color: #3496FB;
          }

        }
      }

      .rules_main {
        padding: .1rem .5rem 0.1rem .1rem;
        color: #484645;

        h4 {
          margin: .08rem 0;
        }

        p {
          height: .32rem;
          line-height: .32rem;
        }

        ul {
          padding-left: .32rem;

          li {
            padding-left: 0;
            margin: .05rem 0;
          }
        }
      }

    }
  }
}
</style>